<template>
  <div>
    <div class="search">
      <div class="title">金刚区列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="名称:">
          <el-input v-model="params.menuName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column label="内部排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.nDept"
            placeholder="请输入排序值"
            @change="setSort(row, 1)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="外部排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.wDept"
            placeholder="请输入排序值"
            @change="setSort(row, 2)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="menuName" label="名称" align="center"> </el-table-column>
      <el-table-column label="展示图 " width="180" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.showImg"
            :preview-src-list="[scope.row.showImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="strType" label="类型" width="120" align="center">
      </el-table-column>

      <el-table-column prop="addTime" label="注册时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:" prop="menuName">
          <el-input v-model="addList.menuName" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="分类展示图片:" prop="showImg">
          <send-image
            type="one"
            :images="addList.showImg"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
          <div class="title_block">图片不能大于50k，建议尺寸为52*44</div>
        </el-form-item>
        <el-form-item label="类型:" prop="menuType">
          <el-select v-model="addList.menuType" placeholder="请选择">
            <el-option label="门票" :value="1"></el-option>
            <el-option label="酒店" :value="2"></el-option>
            <el-option label="线路" :value="3"></el-option>
            <el-option label="万旅卡" :value="4"></el-option>
            <el-option label="精选商城" :value="5"></el-option>
            <el-option label="餐饮美食" :value="6"></el-option>
            <el-option label="超值礼包" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型ID:" prop="menuTypeId">
          <el-input v-model="addList.menuTypeId" style="width: 200px"></el-input>
          <div v-if="addList.menuType == 3" class="title_block">请输入线路一级分类Id</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  disList,
  disListAdd,
  disListSort,
  disListDel,
} from "../../../api/wanxiaoShop.js";
import sendImage from "@/components/sendImage.vue";
export default {
  components: {
    sendImage,
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        menuName: "",
      },
      addList: {
        menuId: 0,
        menuName: "",
        showImg: "",
        menuType: 1,
        menuTypeId: 0,
      },
      addRules: {
        menuName: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        showImg: [{ required: true, message: "请上传分类展示图片", trigger: "blur" }],
        menuType: [{ required: true, message: "请选择类型", trigger: "blur" }],
        menuTypeId: [{ required: true, message: "请输入类型ID", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await disList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    setSort(row, type) {
      let params = {
        menuId: row.menuId,
        dept: type == 1 ? row.nDept : row.wDept,
        deptType: type,
      };
      disListSort(params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    addImageStr(e, type) {
      this.addList.showImg = e;
    },
    add() {
      this.addList.menuId = 0;
      this.addList.menuName = "";
      this.addList.showImg = "";
      this.addList.menuType = 1;
      this.addList.menuTypeId = 0;
      this.dialogVisible = true;
    },
    edit(row) {
      Object.keys(this.addList).forEach((key) => {
        this.addList[key] = row[key];
      });

      this.dialogVisible = true;
    },
    remove(row) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await disListDel({ menuId: row.menuId });
          if (data.code == 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          const { data } = await disListAdd(this.addList);
          this.load = false;
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
